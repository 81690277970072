<script>
import axios from "axios";

export default {
  name: "vocRow",

  data() {
    return {
      replyComment: ''
    }
  },

  props: {
    item: {
      type: Object,
    }
  },

  methods: {
    insertComment(val) {
      const url = `/api/comment/insert`

      const data = {
        document_srl: val.document_srl,
        content: this.replyComment
      }

      axios.post(url, data)
          .then((res) => {
            console.log(res.data)
            if (res.data.message === 'success') {
              alert(res.data.message)
            }
          })
          .catch((err) => {
            console.log(err)
          })
    },
  }
}
</script>

<template>
  <div>
    <div class="timeline-block">
      <div class="timeline-box card">
        <div class="VocCardBody card-body">
          <!-- 회원의 코멘트란 -->
          <div class="headerArea">
            <div class="nameDateArea">
                      <span
                          style="font-size: 0.7em"
                          v-if="false"
                      >
                        {{ "(미인증고객)" }}
                      </span>
              <p class="nameDate card-title mt-0">
                        <span v-if="false">
                          {{

                          }}
                        </span>
                <span>
                          {{
                    `이름 (닉네임) : ${item.nick_name}`
                  }}
                        </span>
                <span style="font-size: 0.8em">{{ }}</span>
              </p>
            </div>
            <div>
              <p class="card-text" style="position: relative">
                &nbsp;
                <span style="position: absolute; left: 1px; font-size: 1.5rem">
                          {{ `내용 : ${item.content}` }}
                        </span>
              </p>
            </div>
          </div>
          <!-- 회원의 코멘트란 END -->

          <!-- 마트의 답장란 -->
          <div class="w-100" style="padding-top: 28px">
            <b-card style="margin-bottom: 0; padding: 0">
              <!-- 이전 댓글이 있을 때 -->
              <div
                  style="margin-bottom: 4px"

              >
                <p
                    v-if="false"
                    class="card-title mt-0"
                    style="position: relative; padding: 0"
                >
                  &nbsp;
                  <span
                      style="
                              position: absolute;
                              right: 0;
                              bottom: 0;
                              font-size: 0.8em;
                            "
                  >{{ }}</span
                  >
                </p>
                <b-card-text>{{ `답변` }}</b-card-text>
              </div>
              <!-- 이전 댓글이 없을 때 -->
              <div>
                <div style="margin-bottom: 4px">
                          <textarea
                              v-model="replyComment"
                              class="form-control"
                              placeholder="댓글을 입력해주세요."
                              id="gen-info-description-input"
                              rows="3"
                          ></textarea>
                </div>
              </div>

              <a
                  class="btn btn-primary w-100"
                  @click="insertComment(item)"
              >
                전송
              </a>
              <a
                  class="btn btn-primary w-100"
                  style="background: darkorange; border-color: #eeeeee"
                  v-if="false"
              >
                수정
              </a>
            </b-card>
          </div>
          <!-- 마트의 답장란 END -->
        </div>
      </div>
    </div>
  </div>

</template>

<style scoped>

</style>
