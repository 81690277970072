<script>
import "./voc.scss";
import Layout from "@/views/layouts/main.vue";
import PageHeader from "@/components/page_header_new.vue";
import appConfig from "@/app.config.json";
import {mapState} from "vuex";
import {loggedInfoObject} from "@/state/helpers";
import axios from "axios";
import {msgConfirmOptCenter, msgModalOptCenter} from "@/components/globalFunctions";
import _ from "lodash";
import VocRow from "./components/vocRow.vue";

export default {
  page: {
    title: "고객의 소리",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      vocData: [],
      deviceId: "9876543210",
      title: "고객의 소리",
      items: [
        {
          text: "Timeline",
        },
        {
          text: "Left Timeline",
          active: true,
        },
      ],
      replyComment: "",
      inputMarketSrl: '',
      selectRowData: {}
    };
  },
  methods: {
    checkVocList() {
      return _.isEmpty(this.vocData)
    },

    //목록을 가져와서 기본데이터 삽입
    getVocList() {
      axios.get(
          `/api/market/voc/${this.logged_info.mart_srl}/1/1000000`
      )
          .then((response) => {
            //console.log(response.data)
            this.vocData = response.data.data;

            //console.log("m_voc_srl" in this.vocData[4].answer)

            //console.log(this.vocData)
          })
          .catch((error) => {
            console.log(error);
          });
    },

    getTempVocList(val) {
      console.log('ENTER')

      const postData = {
        member_srl: Number(val),
        category_srl: Number(val)
      }

      axios.post(
          `/api/post/admin/getListAllBoardByFilter/1/999999/regDESC`,
          postData
      )
          .then((response) => {
            console.log(response.data)
            this.vocData = response.data.post_list;

            //console.log("m_voc_srl" in this.vocData[4].answer)

            //console.log(this.vocData)
          })
          .catch((error) => {
            console.log(error);
          });
    },

    submitReply(c_voc_srl, i) {
      this.$bvModal.msgBoxConfirm("답글을 등록하시겠습니까?", msgConfirmOptCenter)
          .then((sel) => {
            if (sel) {
              if (this.vocData[i].c_voc_srl === c_voc_srl) {
                this.processSubmit(this.vocData[i].answer.m_comment, c_voc_srl);
              } else {
                for (var ix = 0; ix <= this.vocData.length; i++) {
                  if (this.vocData[ix].c_voc_srl === c_voc_srl) {
                    this.processSubmit(this.vocData[ix].answer.m_comment, c_voc_srl);
                  }
                }
              }
            }
          })
    },
    processSubmit(comment, index) {
      const fd = new FormData();
      fd.append("deviceid", this.deviceId);
      fd.append("comment", comment);
      fd.append("voc_srl", index);
      console.log("fd  : " + fd);
      axios.post(
          `/api/market/voc/${this.logged_info.mart_srl}`,
          fd
      )
          .then((response) => {
            console.log(response.data);
            if (response.data.status != "success") {
              this.$bvModal.msgBoxOk("이미 답글이 등록되었거나 다른 이유로 답글 등록에 실패하였습니다.", msgModalOptCenter)
            }
            this.getVocList();
          })
          .catch((error) => {
            console.log(error);
          });
    },
    modifyProcess(ind, date, index, m_voc_srl) {
      if (date != "") {
        if (this.vocData[index].idx === ind) {
          console.log(m_voc_srl);
          this.vocData[index].answer.m_date = "";
        } else {
          console.log("22");
          for (var i = 0; i <= this.vocData.length; i++) {
            console.log("33");
            if (this.vocData[i].idx === ind) {
              this.vocData[i].answer.m_date = "";
              return;
            }
          }
        }
      } else {

        this.$bvModal.msgBoxConfirm("답글을 수정하시겠습니까?", msgConfirmOptCenter)
            .then((sel) => {
              if (sel) {
                if (this.vocData[index].answer.m_voc_srl === m_voc_srl) {
                  this.processModify(this.vocData[index].answer.m_comment, m_voc_srl);
                } else {
                  for (var id = 0; id <= this.vocData.length; id++) {
                    if (this.vocData[id].answer.m_voc_srl === m_voc_srl) {
                      this.processModify(
                          this.vocData[id].answer.m_comment,
                          m_voc_srl
                      );
                    }
                  }
                }
              }
            })
      }
    },
    processModify(comment, index) {
      axios.put(
          `/api/market/voc/${this.logged_info.mart_srl}`,
          {
            deviceid: this.deviceId,
            comment: comment,
            voc_srl: index,
          }
      )
          .then((response) => {
            console.log(response.data);
            if (response.data.status != "success") {
              this.$bvModal.msgBoxOk("고객이 이미 답글을 열람하였거나 다른 이유로 답글 수정에 실패하였습니다.", msgModalOptCenter)
            }
            this.getVocList();
          })
          .catch((error) => {
            console.log(error);
          });
    },
  },
  mounted() {
    this.getVocList();
  },
  components: {
    VocRow,
    Layout,
    PageHeader,
  },
  computed: {
    ...mapState(loggedInfoObject),
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>

    <div>
      <input v-model="inputMarketSrl" @keypress.enter="getTempVocList(inputMarketSrl)"/>
    </div>

    <div v-if="!checkVocList()" class="VOC row">
      <div class="col-xl-6">
        <div class="verti-timeline left-timeline">
          <!--<div class="timeline-item left">
              <div class="timeline-block">
                <div class="time-show-btn mt-0">
                  <a href="#" class="btn btn-danger btn-rounded w-lg">2021</a>
                </div>
              </div>
            </div>-->

<!--          <div-->
<!--              v-for="(item, index) in vocData"-->
<!--              :key="index"-->
<!--              :class="`timeline-item`"-->
<!--          >-->
<!--            <div class="timeline-block">-->
<!--              <div class="timeline-box card">-->
<!--                <div class="VocCardBody card-body">-->
<!--                  &lt;!&ndash; 회원의 코멘트란 &ndash;&gt;-->
<!--                  <div class="headerArea">-->
<!--                    <div class="nameDateArea">-->
<!--                      <span-->
<!--                          style="font-size: 0.7em"-->
<!--                          v-if="false"-->
<!--                      >-->
<!--                        {{ "(미인증고객)" }}-->
<!--                      </span>-->
<!--                      <p class="nameDate card-title mt-0">-->
<!--                        <span v-if="false">-->
<!--                          {{-->

<!--                          }}-->
<!--                        </span>-->
<!--                        <span>-->
<!--                          {{-->
<!--                            `이름 (닉네임) : ${item.nick_name}`-->
<!--                          }}-->
<!--                        </span>-->
<!--                        <span style="font-size: 0.8em">{{ }}</span>-->
<!--                      </p>-->
<!--                    </div>-->
<!--                    <div>-->
<!--                      <p class="card-text" style="position: relative">-->
<!--                        &nbsp;-->
<!--                        <span style="position: absolute; left: 1px; font-size: 1.5rem">-->
<!--                          {{ `내용 : ${item.content}` }}-->
<!--                        </span>-->
<!--                      </p>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  &lt;!&ndash; 회원의 코멘트란 END &ndash;&gt;-->

<!--                  &lt;!&ndash; 마트의 답장란 &ndash;&gt;-->
<!--                  <div class="w-100" style="padding-top: 28px">-->
<!--                    <b-card style="margin-bottom: 0; padding: 0">-->
<!--                      &lt;!&ndash; 이전 댓글이 있을 때 &ndash;&gt;-->
<!--                      <div-->
<!--                          style="margin-bottom: 4px"-->

<!--                      >-->
<!--                        <p-->
<!--                            v-if="false"-->
<!--                            class="card-title mt-0"-->
<!--                            style="position: relative; padding: 0"-->
<!--                        >-->
<!--                          &nbsp;-->
<!--                          <span-->
<!--                              style="-->
<!--                              position: absolute;-->
<!--                              right: 0;-->
<!--                              bottom: 0;-->
<!--                              font-size: 0.8em;-->
<!--                            "-->
<!--                          >{{ }}</span-->
<!--                          >-->
<!--                        </p>-->
<!--                        <b-card-text>{{ `답변` }}</b-card-text>-->
<!--                      </div>-->
<!--                      &lt;!&ndash; 이전 댓글이 없을 때 &ndash;&gt;-->
<!--                      <div>-->
<!--                        <div style="margin-bottom: 4px">-->
<!--                          <textarea-->
<!--                              v-model="replyComment"-->
<!--                              class="form-control"-->
<!--                              placeholder="댓글을 입력해주세요."-->
<!--                              id="gen-info-description-input"-->
<!--                              rows="3"-->
<!--                          ></textarea>-->
<!--                        </div>-->
<!--                      </div>-->

<!--                      <a-->
<!--                          class="btn btn-primary w-100"-->
<!--                          @click="insertComment(item)"-->
<!--                      >-->
<!--                        전송-->
<!--                      </a>-->
<!--                      <a-->
<!--                          class="btn btn-primary w-100"-->
<!--                          style="background: darkorange; border-color: #eeeeee"-->
<!--                          v-if="false"-->
<!--                      >-->
<!--                        수정-->
<!--                      </a>-->
<!--                    </b-card>-->
<!--                  </div>-->
<!--                  &lt;!&ndash; 마트의 답장란 END &ndash;&gt;-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

          <voc-row
              v-for="(item, index) in vocData"
              :key="index"
              :class="`timeline-item`"
              :item="item"
          ></voc-row>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
.w-100 > div.card > div.card-body {
  margin-bottom: 0;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
